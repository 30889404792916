<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-avatar size="70px" :src="require('@/assets/images/logo/logo.png')" />
        <h2 class="brand-text text-primary ml-1 mt-1">
          منصة النسور الرقمية
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            منصة المنتسبين 👋
          </b-card-title>
          <b-card-text class="mb-2">
            اهلا وسهلا بكم في منصة النسور الرقمي
          </b-card-text>
          <!-- <GoogleLogin
            :params="params"
            :renderParams="renderParams"
            :onSuccess="onSuccess"
            :onFailure="onFailure"
          ></GoogleLogin> -->
          <b-form-group label="الايميل" label-for="Email">
            <b-form-input
              id="Email"
              v-model="google_form.email"
              placeholder="name@nuc.edu.iq"
            />
          </b-form-group>
          <b-form-group label="الباسورد" label-for="password">

          <b-input-group>
            <b-form-input
              v-model="google_form.password"
              id="password"
              :type="passwordFieldType"
              placeholder="**********"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          </b-form-group>
          
          <b-button type="click" variant="primary" @click="loginByGoogle">
            Sign in
          </b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BAvatar,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import GoogleLogin from "vue-google-login";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BAvatar,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    togglePasswordVisibility,
    ValidationObserver,
    GoogleLogin,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "admin",
      userEmail: "admin@demo.com",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
      notifi: {
        route: "",
        title: "",
        item_id: "",
        table: "",
        users: [],
        departments: [],
        role_id: "",
        type: "",
        subtitle: "",
        bg: "",
      },
      google_form: {
        full_name: null,
        phone: "00000000000",
        email: null,
        password: null,
        image_url: null,
        type: null,
      },
      params: {
        client_id:
          "174742659541-o6v7mgd1i2gm7fr1oq34i0f9haoqvc9d.apps.googleusercontent.com", //local
        // "445058199836-6uavenprdrfb9u85gn699np0s7kq1nm6.apps.googleusercontent.com", //local
        // "445058199836-j725hq4hgnpuvnr7k5ormgdd2f8d82sl.apps.googleusercontent.com"//portal
        // "445058199836-l80jc7q79thved5obi7p10cv5hh84p86.apps.googleusercontent.com"//ahmeddev
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    };
  },

  created() {
    if (localStorage.getItem("NisourToken")) {
      this.$router.push("/");
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),
    // async loginByGoogle(password, image, name, email) {
    async loginByGoogle() {
      // this.google_form.email = email;
      // this.google_form.password = password;
      // this.google_form.image_url = image;
      // this.google_form.full_name = name;
      // this.google_form.type = "google";
      if (this.google_form.email && this.google_form.password) {
        await this.axios.post("auth/login", this.google_form).then((res) => {
          localStorage.setItem("NisourToken", res.data.message.token);
          this.checkUser(res.data.message.status);
          this.storeInfo();
          store.commit("storeToken", res.data.message.token);
          if (this.google_form.email.includes("@nuc.edu.iq")) {
            this.notifi.route = "users-list-notif";
            this.notifi.title = "log in action";
            this.notifi.item_id = res.data.message.id;
            this.notifi.table = "users";
            this.notifi.role_id = 1;
            this.notifi.type = "light-success";
            this.notifi.subtitle = "loged in to portal";
            this.notifi.bg = "bg-success";
            this.sendNoti();
            this.makeToast(
              "success",
              "Login",
              "you loged to portal"
            );
          }
        }).catch((e) => {
          this.$router.push("/login");
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          }
        });
      } else {
        console.log("somethink want wrong");
      }
    },
    async sendNoti() {
      await this.axios.post(`notifications`, this.notifi);
    },
    onSuccess(googleUser) {
      this.loginByGoogle(
        Object.values(googleUser.getBasicProfile())[0],
        Object.values(googleUser.getBasicProfile())[4],
        Object.values(googleUser.getBasicProfile())[1],
        Object.values(googleUser.getBasicProfile())[5]
      );
    },
    storeInfo() {
      this.axios
        .get("users/me")
        .then((res) => {
          store.commit("storeInfo", res);
        })
        .catch((e) => {
          this.$router.push("/auth/welcome");
        });
    },
    checkUser(status) {
      if (localStorage.getItem("NisourToken")) {
        this.$router.push("/auth/welcome");
      } else {
        this.$router.push("/login");
      }
    },
    makeToast(variant = null, title = null, body = null) {
      console.log("toasted")
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
